:root {
  --primaryColor: #0089cf;
  --secondColor: #ffb606;
  --listBackground: white;
  --fixed-width: 620px;
  --clr-primary-5: hsl(205, 78%, 60%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --radius: 0.25rem;
  --transition: all 0.3s linear;
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --font-color: #2b394a;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
}
.App {
  text-align: center;
  position: relative;
  height: 100%;
}

.navbar-toggler {
  border: var(--secondColor) solid 3px !important;
  /* border: none !important; */
  border-radius: 2px;
}

#mobileMenu {
  display: none;
}
.mobileViewNavLink {
  display: none !important;
}
#loginMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#loginMobile .loginForAll {
  height: 10rem;
  width: 20rem;
  display: flex;
  justify-content: center;
  border: 0.6rem solid var(--secondColor);
}
#loginMobile .loginForAll a {
  font-size: 3rem;
  font-weight: 600;
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* All Page Common */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html {
  font-size: 62.5%;
}
a,
a:hover {
  text-decoration: none !important;
  cursor: pointer;
}
.heading1 {
  font-size: 4rem;
  color: var(--primaryColor);
}
.heading2 {
  font-size: 3.5rem;
  color: var(--primaryColor);
}

.heading3 {
  font-size: 3rem;
  color: var(--primaryColor);
}
.heading4 {
  font-size: 2.5rem;
  color: var(--primaryColor);
  margin-bottom: 2rem;
}
.heading5 {
  font-size: 2rem;
  color: var(--primaryColor);
}

.heading6 {
  font-size: 1.8rem;
  color: var(--primaryColor);
}
.allText {
  font-size: 1.8rem;
  text-align: justify;
  color: #000f3a;
  line-height: 1.8;
}
.primaryHeading {
  color: #0089cf;
}
#mobileMenuButton button {
  display: none;
}
/* Top News */

/* socialIcon */
.topNews {
  width: 52vw;
  white-space: nowrap;
  display: inline-block;
  list-style: none;
}
.brandImg {
  display: flex;
  justify-content: center;
}
.mujibLOGO {
  position: absolute;
  width: 10rem;
  height: 8rem;
  right: 8rem;
}
.TopnewsLinks {
  display: inline-block;
}
.TopnewsLinks .topnewsSaparator {
  margin: 0.5rem;
}
.topnewsSaparator {
  margin: 0 0.2rem;
  color: #cacaca;
  font-size: 1.3rem !important;
  font-weight: 600;
}
.QuickLinksStyle {
  text-decoration: none;
  font-size: 1.6rem;
  color: var(--secondColor);
  font-weight: 600;
}

#career {
  width: 95%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 2rem;
  padding: 2rem;
}
#career .career {
  text-align: center;
}
#career .career .heading2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
}
.career .CareerLinks {
  text-align: right;
}
.career .CareerLinks ul li {
  text-decoration: none;
  list-style-type: none;
  display: inline-block;
}
.career .CareerLinks ul li a {
  font-size: 1.6rem;
  margin-right: 0.6rem;
  font-weight: 700;
}
.CareerLinks .heading5 {
  color: black;
  font-size: 1.6rem;
  font-weight: 600;
}
.career img {
  width: 90%;
  margin-left: 8rem;
}
.career .CareerSubLinks {
  margin: 2rem;
}
.career .CareerSubLinks ul li {
  text-decoration: none;
  list-style-type: none;
}
.career .CareerSubLinks ul li a {
  font-size: 1.8rem;
  margin-right: 0.6rem;
  font-weight: 700;
  padding: 1rem;
}

.career .wrapper {
  text-align: center;
}
.social-icons {
  margin-left: 2rem;
  margin-top: 0.4rem;
}

.social-icons a {
  font-size: 1.3rem !important;
  height: 2.6rem !important;
  width: 2.6rem !important;
  line-height: 2.6rem !important;
  margin: 0;
  /* margin-left: 1rem !important; */
  border-radius: 100%;
}
#departMentList > div.sub-menu-block {
  visibility: hidden;
  background-color: #f2f2f2;
  position: absolute;
  margin-top: 1.2rem;
  width: 100%;
  left: 0;
  box-sizing: border-box;
  z-index: 3;
  font-size: 16px;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  opacity: 0;

  /*CSS animation applied for sub menu : Slide from Top */
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}
#departMentList:hover > div.sub-menu-block {
  background-color: #f2f2f2;
  visibility: visible;
  opacity: 1;
  z-index: 1025;
  height: 30vh;
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
#allDepartmentName {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  align-self: center;
  position: relative;
  width: 100%;
}
#allDepartmentName .faculties {
  margin: 0.5rem;
  text-align: left;
}
/* #allDepartmentName .gapBus {
  margin-right: 2.4rem;
}
#allDepartmentName .gapEng {
  margin-right: 1.4rem;
} */
#allDepartmentName .faculties .heading5 {
  background-color: var(--primaryColor);
  color: white !important;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  border-bottom: 0.5rem solid var(--secondColor);
}
#allDepartmentName .faculties .heading5 .active {
  background-color: var(--primaryColor) !important;
}
#allDepartmentName .faculties .list-group .list-group-item {
  background-color: transparent;
  border: none;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 0;
}
#alumni > .alumniMenu,
#login > .loginMenu {
  visibility: hidden;
  background-color: #f2f2f2;
  position: absolute;
  margin: 1.2rem 0 1.2rem -1.2rem;
  box-sizing: border-box;
  z-index: 3;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  -webkit-transform: rotateX(90deg);
  -moz-transform: rotateX(90deg);
  -ms-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transform-origin: top center;
  -ms-transform-origin: top center;
  transform-origin: top center;
}
#alumni:hover > .alumniMenu,
#login:hover > .loginMenu {
  background-color: #f2f2f2;
  visibility: visible;
  opacity: 1;
  z-index: 1001;

  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
#alumni > .alumniMenu ul li,
#login > .loginMenu ul li {
  display: block;
  text-align: left;
  border: none;
  border-bottom: 0.1rem solid #ccc;
}
.quickLinks {
  margin: 0 1rem 0 0;
  padding: 0;
  margin-left: 0.5rem;
}
.quickLinks ul li {
  display: inline-block;
  list-style: none;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--primaryColor);
  margin-left: 0.1rem;
  cursor: pointer;
}
.quickLinks ul li a {
  color: var(--primaryColor) !important;
}
.footer-into {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}
.social-icons .youtube:hover {
  color: #ff0000;
  background-color: white;
}
.youtube {
  color: #ff0000;
}
.facebook {
  color: #4968ad;
}
.instagram {
  color: #3f729b;
}
.rowC {
  display: flex;
  flex-direction: row;
  min-height: 6vh;
  width: 100vw;
  /* justify-content: space-between; */
  margin: 0 1rem;
}
.sameRow {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* Navigation */
.NavbarBackground {
  background-color: #f2f2f2;
  height: 100%;
}
.navbar-brand {
  display: none !important;
  font-size: 2rem !important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;..");
}
#navMenu .nav-bar {
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 1rem !important;
  border-bottom: solid 5px var(--secondColor);
  background-color: var(--primaryColor);
  min-height: 6vh;
  top: 0;
  margin: 0;
}
#navMenu .nav .active {
  background-color: var(--secondColor);
  color: white !important;
}
nav a {
  justify-content: right;
  align-content: flex-end;
  color: white !important;
  font-size: 1.8rem;
  font-weight: 600;
  /* letter-spacing: 0.1rem; */
  text-decoration: none !important;
  padding: 1rem !important;
  display: block;
  /* margin-right: 1rem; */
  margin-bottom: 0;
}
#navMenu > .nav-link:hover {
  color: #0089cf !important;
  background-color: var(--secondColor);
}
.nav .sub-menu-block .leftSideSubMenu .card ul li .dropdown-menu,
.nav .sub-menu-block .centerSubMenu .card ul li .dropdown-menu,
.nav .sub-menu-block .rightSideSubMenu .card ul li .dropdown-menu {
  background-color: var(--primaryColor);
  width: 100%;
  margin-left: -1.2rem;
  border: none !important;
}
.nav
  .sub-menu-block
  .leftSideSubMenu
  .card
  ul
  li
  .dropdown-menu
  .list-group-item,
.nav .sub-menu-block .centerSubMenu .card ul li .dropdown-menu .list-group-item,
.nav
  .sub-menu-block
  .rightSideSubMenu
  .card
  ul
  li
  .dropdown-menu
  .list-group-item {
  background-color: transparent !important;
  color: white;
  border-right: none;
  border-left: none;
  border-bottom: rgba(255, 182, 6, 0.8) 0.1rem solid;
}
.nav
  .sub-menu-block
  .leftSideSubMenu
  .card
  ul
  li
  .dropdown-menu
  .list-group-item
  a,
.nav
  .sub-menu-block
  .centerSubMenu
  .card
  ul
  li
  .dropdown-menu
  .list-group-item
  a,
.nav
  .sub-menu-block
  .rightSideSubMenu
  .card
  ul
  li
  .dropdown-menu
  .list-group-item
  a {
  color: white !important;
  font-size: 1.6rem;
  font-weight: 600;
}
.navbar-light .navbar-toggler {
  color: white;
}
.blinking {
  animation: blinkingText 1.2s infinite;
}

@keyframes blinkingText {
  0% {
    color: white;
  }
  49% {
    color: white;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: white;
  }
}

/* Create three equal columns that floats next to each other */
.column {
  float: left;
  width: 33.33%;
  padding: 10px;
  background-color: #ccc;
  height: 250px;
}

.column a {
  float: none;
  color: black;
  padding: 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.column a:hover {
  background-color: #ddd;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Submenu Bar */
.subMenu {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2rem;
  background-color: #f2f2f2;
  transition: display ease-in-out 5s;
  transition-delay: 0.5s;
  max-height: 45vh;
}
.sub-menu-block {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 2rem;
  background-color: #f2f2f2;
  transition: display ease-in-out 5s;
  transition-delay: 0.5s;
  max-height: 55vh;
}
.sub-menu-block li .nav-item a {
  color: var(--primaryColor);
}
#navbarDropdown,
.dropdown-menu li a {
  color: var(--primaryColor) !important;
}
.leftSideSubMenu {
  width: 30%;
  margin: 1rem;
}
.centerSubMenu {
  width: 30%;
  margin: 1rem;
}
.rightSideSubMenu {
  width: 30%;
  margin: 1rem;
}

.leftSideSubMenu .card li,
.centerSubMenu .card li,
.rightSideSubMenu .card li {
  font-size: 1.6rem;
  color: var(--primaryColor);
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  padding: 0.5rem 1.25rem !important;
}
.leftSideSubMenu .card li a,
.centerSubMenu .card li a,
.rightSideSubMenu .card li a {
  font-size: 1.6rem;
  color: var(--primaryColor) !important;
  background-color: transparent;
  cursor: pointer;
  text-align: left;
  padding: 0.5rem 1.25rem !important;
}
.leftSideSubMenu .card,
.centerSubMenu .card,
.rightSideSubMenu .card {
  border: none;
  background-color: transparent;
}

.centerSubMenu .card p {
  font-size: 1.6rem;
  display: flex;
  text-align: justify;
  justify-content: center;
  align-items: center;
  color: #000;
}
.campusAddress p,
.campusAddress h1 {
  margin-top: 1rem;
  color: var(--primaryColor);
}
.campusHeader {
  color: #000;
  margin-left: 2.5rem;
  text-align: left !important;
  justify-content: left !important;
}

.subMenu .nav-link:hover {
  color: #0089cf;
  background-color: transparent !important;
}
.subMenu .nav-link {
  color: #0089cf !important;
}
.dropdown-menu .dropdown-item {
  font-size: 1.5rem;
}
.sub-menu-block .dropdown-menu {
  border: none !important;
}

/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    height: auto;
  }
}

/* Home Page */
/* Slider */
.hero-slide {
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
.slide-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.hero-slider .content {
  position: relative;
  z-index: 2;
}
.slide-image img {
  width: 100vw;
  height: 80vh;
}
/* About us */
#President {
  width: 25rem;
}
.rightSideFront {
  text-align: center;
}
.proctorContainer {
  display: flex;
  flex-wrap: wrap;
}
.proctorContainer .card {
  width: 50%;
  height: 100%;
  padding: 0.5rem;
}
.proctorContainer .card .card-body .allText {
  margin-bottom: 0 !important;
}
.proctorImageSize {
  height: 16rem;
  width: 25%;
}
.proctorContainer .card .imageSize {
  width: 60%;
  height: 16rem;
}
.table tr,
td {
  font-size: 1.6rem;
}
.officeImg tr img {
  width: 30rem;
  border: #c2c2c2 5px solid;
}
.messagePic {
  width: 300px;
}
/* Contact Info */
.contactInfo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem;
}

.contactInfo .info {
  width: 50%;
}
.contactInfo .contactImg {
  width: 30%;
}
.locationContainer {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 1rem;
}
.locationText {
  padding: 2rem;
  flex: 1;
}
.iframeContainer {
  flex: 2;
  padding: 1rem;
}
.iframeContainer iframe {
  width: 100%;
  height: 450px;
  border: none;
}

/* Syndicate */
.comitteeMemberList .allText {
  text-align: left !important;
}

#allOtherOffices {
  display: flex;
  flex-direction: column;
}
#allOtherOffices .OtherPagecontent {
  height: unset;
}

#allOtherOffices.OtherpageSideBar .messageContent {
  margin-bottom: 4rem;
}

.formerChairmen {
  display: flex;
  flex-wrap: wrap;
}
.formerChairmen .item {
  width: 30%;
  margin-right: 2rem;
}
.formerChairmen .item img {
  width: 100%;
}

/* facultyList */
#faculty .title {
  position: relative;
  top: 5rem;
  left: 4rem;
  font-weight: 700;
  text-align: left;
}

#faculty {
  height: 85vh;
}
.faculty {
  padding-left: 10rem;
  padding-top: 7rem;
  padding-right: 10rem;
  padding-bottom: 7rem;
}
.facultyName {
  color: black;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 700;
}
#faculty .item {
  overflow: hidden;
}
#faculty .item .card {
  border: none;
}
#faculty .item .card:hover {
  transform: scale(1.1);
  transition: all 1s ease-in-out;
}
#faculty .card a {
  text-decoration: none;
}
/* Academic pages */
/* Online Payment Procedure Image */
#onlinePaymentProcedure .card .card-body {
  flex: 0 1 auto;
  align-items: flex-start;
}
#onlinePaymentProcedure .card {
  cursor: pointer;
}
#onlinePaymentProcedure .card img {
  transition: all ease-in-out 2s;
}

/* Admission */
/* Admission for PC */
.tutionFee th {
  color: var(--primaryColor);
}
.AdmissionLocation {
  background-color: #8faeca;
  border: #000 2px solid;
}
.AdmissionLocation p,
.AdmissionLocation a {
  color: #000;
}

.admissionOfficeEmployee {
  display: flex;
  background-color: #8faeca;
  border: #000 2px solid;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.admissionOfficeEmployee .details {
  width: 70%;
  height: auto;
  overflow: hidden;
  padding: 0.5rem;
}
.admissionOfficeEmployee .image {
  width: 30%;
  height: 100%;
}
.admissionOfficeEmployee .details p {
  line-height: 1.6rem;
  font-size: 16px;
}

.modalCloseButton:hover {
  background-color: #dc2743 !important;
  color: #fff;
}

ol > li::marker {
  font-weight: bold;
}

/* Clubs */
.clubsMain {
  width: 90vw;
  margin: 5rem auto;
  background: var(--clr-white);
  border-radius: var(--radius);
  padding: 2.5rem 2rem;
  max-width: var(--fixed-width);
  display: grid;
  gap: 1rem 2rem;
}
.clubsMain h3 {
  font-size: 2.4rem;
  font-weight: 800;
  color: var(--font-color);
}
.clubInfo,
.publications {
  padding: 1rem 1.5rem;
  border: 2px solid var(--clr-grey-special);
  margin-bottom: 1rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
}

.clubInfo h4 {
  text-transform: none;
  line-height: 1.5;
}
.clubInfo allText {
  color: var(--clr-grey-3);
  margin-bottom: 0;
  margin-top: 0.5rem;
}
.clubInfo header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clubInfo header h4 {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 2rem;
}
.clubIcon {
  color: var(--secondColor);
  font-size: 1.8rem;
  font-weight: 600;
}

/* Research */
blockquote {
  font-size: 1.9rem;
  text-align: center;
  font-weight: 700;
}

/* Journal */
#journal ul li {
  list-style-type: square;
}

#journal ul p {
  font-style: italic;
  font-weight: 600;
}

#journal .heading4 {
  margin: 5rem 0 2rem 0;
}

#editorialBoard p {
  line-height: 1;
}
#editorialBoard {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

/* Faculty Pages */
.imageWithName {
  position: relative;
  text-align: center;
  background-color: white;
  width: 100vw;
  height: 10rem;
  border-bottom: 0.6rem solid var(--secondColor);
}
.imageWithName .centered {
  position: absolute;
  top: 50%;
  left: 47%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 600;
  color: var(--primaryColor);
}

.imageWithName img {
  width: 100vw;
  height: 25vh;
  border-bottom: 5px solid var(--secondColor);
}
/* Left Sidemenu */
.dropdown-menu.show {
  background-color: #f2f2f2;
  border: none;
}

/* Faculty Left Sidebar start here */
#facultyLeftSideMenu {
  width: 20%;
  margin: 2.2rem 2rem !important;
}
#facultyLeftSideMenu .dropdown-submenu {
  position: relative;
}

#facultyLeftSideMenu .dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  min-width: 20%;
  margin-top: -1px;
  background-color: var(--listBackground);
}
#facultyLeftSideMenu ul li a {
  font-size: 1.8rem;
  text-decoration: none;
}
#facultyLeftSideMenu ul li {
  background-color: var(--listBackground);
}
#facultyLeftSideMenu button {
  font-size: 1.8rem;
  border: none;
  color: var(--primaryColor);
}
.sidemenuBar {
  /* padding-top: 5rem; */
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  color: var(--primaryColor);
  background-color: #f2f2f2;
  /* border-top: 0.5rem var(--primaryColor) solid; */
}
.menu .transition {
  width: 100%;
}
.sidemenuBar .sideMenuBarDropdown {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: rgba(255, 182, 6, 0.8) 0.1rem solid !important;
}

.sideMenuBarDropdown .item {
  background-color: var(--primaryColor);
  width: 100%;
  border: none !important;
  color: white;
  padding: 0.7rem 0.9rem !important;
  border-bottom: rgba(255, 182, 6, 0.8) 0.1rem solid !important;
}
.sideMenuBarDropdown .item a {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
}
.sideBarSingleItem {
  text-align: left;
  padding: 0.5rem 1rem;
  border-bottom: rgba(255, 182, 6, 0.8) 0.1rem solid !important;
}
.item.sideBarSingleItem a,
.sideBarSingleItemLast a {
  color: var(--primaryColor) !important;
}
.ui.menu .ui.dropdown .menu .active.item {
  background-color: var(--primaryColor) !important;
}
.ui.dropdown .menu .active.item {
  background-color: rgba(0, 0, 0, 0.6);
}
.sideBarSingleItemLast {
  text-align: left;
  padding: 0.5rem 1rem;
}
.transition .divider {
  font-size: 1.8rem;
  color: white;
}
.ui.dropdown .menu > .item:hover {
  background-color: var(--primaryColor) !important;
  color: white !important;
}
/* Faculty Left Sidebar end here */

.facultyContent {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.rightSideContent {
  width: 75%;
  background-color: white;
  text-align: left;
  margin-right: 2rem;
}
.rightSideContent .departmentClubs .OtherPagecontent {
  margin-top: 0;
}
/* tution Fees for Department and faculty */
.rightSideContent .content .OtherPagecontent {
  margin: 0;
  margin-top: 2rem;
}
.facultySliderandHeader {
  display: flex;
  background-color: var(--listBackground);
  align-items: flex-start;
  justify-content: center;
}
.facultySlider {
  width: 78%;
}

.DeptSidemenu {
  width: 20%;
  margin: 0.5rem;
}
.DeptRightSide {
  width: 75%;
}

.DeptRightSideNewsAndEvents {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.DeptRightSideNewsAndEvents #news,
.DeptRightSideNewsAndEvents #noticeAndEventsRapper {
  padding-bottom: 0;
}

.deanMessage {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
#deanArts .deanMessage {
  flex-direction: column;
  padding: 0.3rem;
}
#deanArts .deanMessage p {
  width: 100%;
}
.deanMessage img {
  width: 20%;
  margin: 0;
  margin-right: 2rem;
}
.deanMessage p {
  width: 80%;
}
#deanArts .deanMessage img {
  margin: 2rem 2rem 2rem 0;
}
.deansAndChairperson {
  display: flex;
  flex-wrap: wrap;
}
.deansAndChairperson .card {
  width: 25rem;
  margin: 1rem;
}
.DeptRightSide button {
  border: none;
  background-color: transparent;
  color: var(--primaryColor);
}
.sidemenu ul li {
  list-style-type: none;
  font-size: 2rem;
  text-align: left;
}
/* Department Pages */
.DeptImgSlider {
  width: 75%;
}
.departments {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  padding-left: 4rem;
}
#DepartmentName {
  display: flex;
  text-align: left;
}
#DepartmentName a {
  position: relative;

  margin-right: 6rem;
}
#DepartmentName a .card img {
  width: 350px;
  height: 200px;
}

#DepartmentName .card .deptContent {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1; /* Grey text */
  width: 100%; /* Full width */
  height: 10vh;
}
#DepartmentName .card .deptContent .heading5 {
  font-weight: 700;
  color: white !important;
  margin-left: 2rem;
  margin-bottom: 0;
}
#DepartmentName .card .deptContent p .fa-chevron-circle-right {
  color: #ffb606;
  font-size: 2rem;
  margin-left: 2rem;
  margin-right: 1rem;
}
#DepartmentName .card .deptContent p {
  font-size: 2rem;
}

/* welcome Message */
.contactInfoWelcomeMessage {
  margin-top: 2rem;
}
.contactInfoWelcomeMessage p {
  margin: 0;
}

/* Research  groups */
.researchName {
  font-size: 2.3rem;
}
#researchGroup .card {
  padding: 1rem;
  padding-left: 4rem;
}
#researchGroup .card ul li {
  list-style-type: none;
  font-size: 1.8rem;
  color: var(--primaryColor);
  padding-left: 1.8rem;
}
#researchGroup table .allText {
  text-align: left;
  line-height: 1.2;
}

/* News */
.NewsAndNoticeContainer {
  padding-top: 7rem;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
#news {
  width: 50%;
  padding-top: 0;
  padding-right: 4rem;
  padding-bottom: 7rem;
}
#news .item .card {
  margin: 0.8rem;
  border: none;
}
#news .item .card .card-body {
  padding-left: 0;
}
#news .owl-carousel .owl-item img {
  /* border-bottom: 0.7rem solid var(--secondColor);
    border-top: 0.7rem solid var(--secondColor); */
  height: 20rem;
  width: 100%;
}
#news .newsFooter {
  display: flex;
  width: 100%;
  padding-left: 0;
}

.month {
  font-size: 1.5rem;
  margin: 0;
  width: 8rem;
}
.day {
  font-size: 4rem;
  font-weight: 700;
}
#news .newsFooter .newsHeadlines {
  width: 100%;
  text-align: left;
}
#news .newsFooter .newsHeadlines .heading5 {
  color: #2b394a;
  font-weight: 600;
}
#news .newsFooter .newsHeadlines .publsihDate {
  font-size: 1.3rem;
  color: #746d69;
}
#news .date {
  padding: 1px;
  border: 1px solid #0089cf;
}
#news .date .month {
  background-color: #0089cf;
  color: white;
}
.newsAndNotice {
  display: flex;
  justify-content: center;
  align-items: center;
}
#newsAll {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: left;
}
#newsAll .card {
  width: 30%;
  margin: 1rem;
}
#newsAll .card img {
  height: 20rem;
  border-bottom: solid 0.3rem var(--secondColor);
}
.newsForDepartment {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  align-content: space-between !important;
  justify-content: left;
  text-align: left;
  /* flex: 0 1 auto; */
}
.newsForDepartment .card {
  width: 30%;
}
.newsForDepartment .card img {
  border-bottom: solid 0.3rem var(--secondColor);
}
/* Notice */
#notice {
  width: 45%;
  padding-bottom: 2rem;
}
.noticeCard ul li .heading5 a {
  color: var(--primaryColor);
}
#NoticeAll {
  display: flex;
  align-content: flex-start;
  justify-content: center;
}
#NoticeAll .card {
  width: 70%;
}
.singlePost {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.singlePost .card {
  max-width: 90%;
  text-align: center;
}
.singlePost .card .card-body img {
  width: 70%;
}
#noticeTab li .active {
  background-color: var(--secondColor);
  color: white;
}
#noticeTab li a {
  color: var(--secondColor);
  font-size: 1.8rem;
  font-weight: 600;
}

/* upcoming events */
#upcomingEventsAll {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}

.Upcoming_Events .card .card-body {
  display: flex;
  text-align: center;
}
.Upcoming_Events .card .card-body .events-date {
  background-color: var(--primaryColor);
  border-radius: 0.2rem;
  margin-right: 3rem;
  height: 9rem;
  border-bottom: 0.8rem solid var(--secondColor);
  padding-top: 1rem;
}
.Upcoming_Events .card .card-body .events-date p {
  margin: 0;
}
.Upcoming_Events .card .card-body .events-date .date {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 3rem;
}
.Upcoming_Events .card .card-body .events-date .month {
  font-size: 2rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}
.events-description {
  margin-top: 0;
  padding-top: 0;
}
#upcomingEventsByDepartment .card {
  margin: 1rem;
}
#upcomingEventsByDepartment .card .card-body {
  display: flex;
}
#upcomingEventsByDepartment .card .card-body .events-date {
  background-color: var(--primaryColor);
  border-radius: 0.2rem;
  margin-right: 3rem;
  height: 9rem;
  border-bottom: 0.8rem solid var(--secondColor);
  padding-top: 1rem;
  text-align: center;
}
/* #upcomingEventsByDepartment .card .card-body .events-date p {
    margin: 0;
  } */
#upcomingEventsByDepartment .card .card-body .events-date .date {
  font-size: 3.5rem;
  font-weight: 700;
  color: white;
  margin: 0;
  padding: 0;
  line-height: 3rem;
}
#upcomingEventsByDepartment .card .card-body .events-date .month {
  font-size: 2rem;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
}

/* Faculty Members */

#FacultyMembers {
  margin: 5rem;
}
#FacultyMembers .item .card img,
.faculty__member {
  /* height: 30rem; */
  height: 20rem;
}
#FacultyMembers .item .card .card-body {
  position: absolute; /* Position the background text */
  bottom: 0; /* At the bottom. Use top:0 to append it to the top */
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  padding: 0;
  width: 100%; /* Full width */
  height: 11vh;
}
.item.departmentCard {
  display: flex;
  gap: 10px;
}
.departmentCard .card {
  width: 30rem;
}
.departmentCard .card img {
  width: 30rem;
}
#FacultyMembers .item .card .card-body .heading6 {
  color: var(--secondColor) !important; /* Grey text */
  font-size: 1.6rem;
  margin-bottom: 0;
}
#FacultyMembers .item .card .card-body .allText {
  color: white;
  font-size: 1.6rem;
}
#facultyMemberDetails .details {
  text-align: center;
  margin: 2rem;
  width: 90%;
}
#facultyMemberDetails .details .allText {
  text-align: left;
}
.facultyMemberDetails .info {
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
}
.facultyMemberDetails .info .contact {
  width: 40%;
}
.facultyMemberDetails .info .heading6 {
  text-align: justify;
}
.facultyMemberDetails .info img {
  max-width: 30rem;
}

.FacultyMembersByDepartment {
  /* display: flex;
  align-items: flex-start;
  justify-content: left;
  flex-wrap: wrap; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 5px;
}

.FacultyMembersByDepartment .card img {
  width: 100%;
  border: #c2c2c2 5px solid;
}

/* Publications */

.publications header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.publicationMain {
  margin: 5rem auto;
  background: var(--clr-white);
  border-radius: var(--radius);
  padding: 2.5rem 2rem;
  max-width: var(--fixed-width);
}
.publications header h4 {
  line-height: 1.5;
  font-size: 2rem;
  font-weight: 600;
}

/* Testimonials */
main {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
  color: Var(--primaryColor);
}
.underline {
  height: 0.25rem;
  width: 5rem;
  background: var(--secondColor);
  margin-left: auto;
  margin-right: auto;
}
.testimonials {
  width: 100vw;
  max-width: var(--fixed-width);
}
.review {
  background: white;
  padding: 1.5rem 2rem;
  border-radius: var(--radius);
  box-shadow: var(--light-shadow);
  transition: var(--transition);
  text-align: left;
}
.review .info button {
  color: var(--primaryColor);
  background-color: transparent;
  border: none;
}
.review:hover {
  box-shadow: var(--dark-shadow);
}
.img-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.person-img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: relative;
}
.quote-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.5rem;
  height: 2.5rem;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transform: translateY(25%);
  background: var(--clr-primary-5);
  color: white;
}
.img-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--clr-primary-5);
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}
.button-container {
  text-align: center;
}
.author {
  margin-bottom: 0.25rem;
  font-size: 2.5rem;
  color: var(--primaryColor);
}
.job {
  margin-bottom: 0.5rem;
  font-size: 1.6rem;
  font-weight: 700;
}
.info {
  margin-bottom: 0.75rem;
}
.prev-btn,
.next-btn {
  color: var(--clr-primary-7);
  font-size: 1.25rem;
  background: transparent;
  border-color: transparent;
  margin: 0 0.5rem;
  transition: var(--transition);
  cursor: pointer;
}
.prev-btn:hover,
.next-btn:hover {
  color: var(--clr-primary-5);
}
/* .list-group-item {
    border-bottom: 1px solid var(--primaryColor) !important;
  } */

/* other pages content */
.OtherPagecontent {
  width: 65vw;
  margin-top: 5rem;
  margin-right: 4rem;
  margin-bottom: 5rem;
  text-align: left;
}
.OtherPagecontent::-webkit-scrollbar {
  display: none;
}
.OtherPagecontent .nav-item .nav-link.active {
  background-color: var(--secondColor);
}
.OtherPagecontent .nav-item .nav-link.active p {
  color: white;
  font-size: 2rem;
  font-weight: 600;
}
.OtherPagecontent ul li {
  list-style-type: none;
}
.OtherpageSideBar {
  width: 26vw;
  margin-top: 13rem;
  position: sticky;
  border-top: var(--primaryColor) 0.3rem solid;
  padding-top: 5rem;
  background-color: #f2f2f2;
  margin-bottom: 10rem;
}
.OtherpageSideBar .nav-link {
  padding: 0;
}
.OtherpageSideBar ul li a {
  color: var(--primaryColor);
}
.OtherpageSideBar .dropdown-menu {
  background-color: var(--primaryColor);
  width: 100%;
  margin-left: -1.2rem;
  border: none !important;
}
.OtherpageSideBar ul li .dropdown-menu .list-group-item {
  background-color: transparent !important;
  color: white !important;
  border-right: none;
  border-left: none;
  border-bottom: rgba(255, 182, 6, 0.8) 0.1rem solid;
}
.OtherpageSideBar ul li .dropdown-menu .list-group-item a {
  color: white !important;
  font-size: 1.8rem;
  font-weight: 600;
}
.OtherpageSideBar ul li .dropdown-menu .list-group-item:hover {
  background-color: var(--primaryColor) !important;
}
.OtherpageSideBar .dropdown-menu .dropdown-item:hover {
  background-color: transparent;
}

.content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.OtherpageSideBar ul li {
  font-size: 1.8rem;
  text-align: left;
  background-color: #f2f2f2;
  font-weight: 600;
}
.OtherpageSideBar ul li:hover {
  background-color: transparent !important;
}
.content .OtherPagecontent .card {
  border: none;
}

/* International Collobration */
.listOfIntnationIns {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: flex-start;
}
.listOfIntnationIns .card img {
  height: 20rem;
}
.listOfIntnationIns .card {
  width: 20%;
  padding: 0.5rem;
}
.listOfIntnationIns .card a {
  text-decoration: none;
}
.internationalHome {
  padding: 7rem 4rem 3rem 4rem;
}
.internationalHome .listOfIntnationIns {
  align-items: flex-start;
  justify-content: left;
  /* border: var(--primaryColor) 0.4rem solid; */
}
.internationalHome .listOfIntnationIns .card {
  margin: 0.4rem;
  width: 10rem !important;
  border: var(--secondColor) solid 0.2rem;
}
.internationalHome .listOfIntnationIns .card img {
  height: 10rem;
}

/* Osa Core Values */
#OsaCoreValues {
  display: flex;
}
#OsaCoreValues .card {
  border: 0.2rem solid var(--primaryColor);
  border-radius: 1rem;
  text-align: center;
}
#OsaCoreValues .card .card-body ul li {
  padding-left: 1rem;
  list-style: square;
}

/* Photo Gallary */
#photoAlbum {
  padding: 7rem 4rem 0 4rem;
}
.photoGallary {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.photoGallary .card {
  width: 15%;
  margin: 1rem;
}
/* General pages Degisn */
.page-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.page-container .container {
  width: 90%;
  list-style-type: none;
}
/* Course Details */
.singleCourse {
  display: flex;
  padding: 4rem 8rem;

  align-content: center;
  justify-content: center;

  flex-direction: column;
}
/*Footer Style*/
.site-footer {
  background-color: #0089cf;
  padding: 7rem 4rem;
  font-size: 13px;
  line-height: 20px;
  color: white;
}

.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}

.site-footer hr.small {
  margin: 20px 0;
}

.site-footer h6 {
  color: #fff;
  font-size: 1.8rem;
  margin-top: 5px;
  letter-spacing: 2px;
}

.site-footer a {
  color: #ffb606;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footerFlex {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
#footer .site-footer .footerFlex .footer-info {
  min-width: 20rem;
  margin-right: 30px !important;
}
#footer .footer-links {
  order: 1;
  padding-left: 0;
  list-style: none;
  min-width: 200px;
  text-align: left;
  margin-bottom: 2rem;
}

.footer-links li {
  display: block;
  margin: 0.4rem 0 0.4rem 0;
  font-size: 1.5rem;
  text-align: left;
}

.footer-links a {
  color: white;
  padding: 0.2rem;
}
.footer-links li a:hover {
  color: #ffb606;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: white;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block;
}

.site-footer .social-icons {
  text-align: right;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #33353d;
}

.copyright-text {
  margin: 0;
}

@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
  html {
    font-size: 58.5%;
  }
}

@media (max-width: 767px) {
  html {
    font-size: 58.5%;
  }
  .site-footer {
    padding-bottom: 0;
  }

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}

.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.instagram:hover {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.social-icons a.twitter:hover {
  background-color: #ff0000;
}

.social-icons a.linkedin:hover {
  background-color: #007bb6;
}

.social-icons a.dribbble:hover {
  background-color: #816f6e;
}

@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}

/* Media Query For Desktop */
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* All Common text for mobile */
  .heading1 {
    font-size: 3rem;
  }
  .heading2 {
    font-size: 2.6rem;
  }

  .heading3 {
    font-size: 2.3rem;
  }
  .heading4 {
    font-size: 2.1rem;
  }
  .heading5 {
    font-size: 2rem;
  }

  .heading6 {
    font-size: 1.8rem;
  }
  .allText {
    font-size: 1.6rem;
    text-align: justify;
    color: #000f3a;
    line-height: 1.5;
  }

  /* Navigation Responsive */
  .navbar-brand {
    display: inline-block !important;
  }
  /* Top news */
  .rowC {
    display: flex;
    flex-direction: row;
    min-height: 3vh;
  }
  /* faculty List */
  #faculty {
    height: unset;
    width: 97%;
    padding: 0;
  }
  #faculty .title {
    position: relative;
    top: 5rem;
    left: 2rem;
    font-weight: 700;
    text-align: left;
  }

  #faculty .item {
    width: 100%;
  }
  .faculty {
    padding-left: 5rem;
    padding-top: 2rem;
    padding-right: 5rem;
    padding-bottom: 2rem;
  }
  .facultyName {
    color: var(--font-color);
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
  }
  /* Topnews Responsive */
  .topNews {
    width: 100vw;
  }
  .quickLinks,
  #topIcons {
    display: none;
  }
  .brandImg {
    width: 100vw !important;
    padding: 3rem 0 3rem 0 !important;
    margin: 0 !important;
    justify-content: center;
  }
  .EasternLOGO {
    width: 70vw;
    margin-left: 2rem;
  }
  .mujibLOGO {
    width: 10vw;
    right: 3.5rem;
  }
  .sub-menu-block {
    display: none;
  }
  /* News and Notice Responsive */
  .NewsAndNoticeContainer {
    flex-direction: column;
    width: 100%;
    padding: 2rem 2rem 0 2rem;
  }
  #news {
    padding-right: 0;
  }
  #news,
  #notice {
    width: 100%;
  }
  #news .newsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* News for department Responsive */
  .newsForDepartment .card {
    width: 100%;
  }

  .events-description p {
    font-size: 1.6rem;
  }
  .Upcoming_Events .card .card-body .events-date {
    background-color: var(--primaryColor);
    border-radius: 0.2rem;
    margin-right: 1rem;
    height: 9rem;
    border-bottom: 0.8rem solid var(--secondColor);
    padding-top: 1rem;
    min-width: 9rem;
  }

  /* testimonials responsive */
  main {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;
  }
  #testimoni .title {
    text-align: left;
    padding-left: 2rem;
    margin-bottom: 4rem;
    color: Var(--primaryColor);
  }
  .underline {
    height: 0.25rem;
    width: 5rem;
    background: var(--secondColor);
    margin: 0;
    margin-left: 5rem;
  }

  .facultyMemberDetails .info {
    flex-direction: column;
  }
  .facultyMemberDetails .info .contact,
  .facultyMemberDetails .info img {
    max-width: 30rem;
  }

  /* International Academic Collaboration responsive*/
  .internationalHome {
    margin: 0;
    padding: 2rem 2rem;
  }
  .internationalHome .listOfIntnationIns .card {
    width: 30% !important;
  }
  /* aLL Notice responsive Mobile */
  #NoticeAll .card {
    width: 100%;
  }
  /* single News Notice Upcoming responsive */
  .singlePost .card {
    width: 95%;
  }
  #newsAll {
    justify-content: center;
  }
  #newsAll .card {
    width: 95%;
    margin: 0;
  }
  /* Photo album responsive */
  #photoAlbum {
    width: 100%;
    padding: 4rem 2rem;
  }
  .photoGallary .card {
    width: 100%;
    margin: 0;
  }
  /* Other pages Responsive*/
  .content {
    flex-direction: column;
    align-items: center;
  }
  .OtherPagecontent {
    width: 100%;
    padding: 1rem 2rem;
    order: 2;
    margin: 0;
    transition: all 3s ease;
  }

  .OtherpageSideBar {
    width: 100vw;
    order: 1;
    position: static;
    margin: 0;
  }
  #allOtherOffices {
    order: 2;
  }
  /* About us Responsive*/
  .formerChairmen {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .formerChairmen .item {
    width: 100%;
  }
  .formerChairmen .item img {
    width: 100%;
    height: unset;
  }
  .memberOfEU .table-responsive table tbody tr td p {
    text-align: left;
  }
  .proctorImageSize {
    height: 15rem;
    width: 60%;
  }
  .proctorContainer .card {
    width: 100%;
  }

  .proctorContainer .card .imageSize {
    height: 100%;
  }

  /* Admission for Mobile */

  .admissionOfficeEmployee .details p {
    line-height: 1.6rem;
    font-size: 12px;
    padding: 1px;
  }
  /* International Colabrotion Responsive */
  .listOfIntnationIns .card {
    width: 15rem !important;
    padding: 0.5rem;
  }
  /* Career Responsive */
  .career img {
    margin: 0;
  }
  /* Contact page */
  /* Department and Faculty Responsive*/
  #facultyLeftSideMenu {
    display: none;
  }
  .sidemenuBar {
    display: none;
  }
  .menu .transition {
    width: 50%;
  }
  #mobileMenuButton {
    height: 6rem;
    width: 6rem;
    position: fixed;
    bottom: 3rem;
    left: 1.5rem;
    z-index: 1002;
    display: inline-block;
    border: 3px solid #ffb606;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: scale(0.8);
    background-color: transparent;
  }
  #mobileMenuButton::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid #ffb606;
    animation: hamburger_puls 1s ease infinite;
  }
  #mobileMenuButton button {
    font-size: 2rem;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    display: block;
  }
  #mobileMenuButton button .moreMenu {
    color: var(--secondColor);
    font-size: 5rem;
    font-weight: 1000;
  }
  #mobileMenuContent {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1021;
    background-color: #f2f2f2;
    border-top: 0.5rem solid var(--primaryColor);
  }
  #mobileMenuContent #facultyLeftSideMenu,
  #mobileMenuContent .sidemenuBar {
    display: block;
    width: 95%;
    background-color: transparent;
    border: none;
  }
  #mobileMenuContent #closeButton {
    position: fixed;
    top: 1rem;
    right: 1.5rem;
    border: none;
    border-radius: 50%;
    background-color: transparent;
  }
  .closeButtonIcon {
    color: var(--secondColor);
    font-size: 3rem;
    font-weight: bolder;
  }
  /* Login Button for Mobile */
  .mobileViewNavLink {
    display: block !important;
  }

  /* faculty responsive Mobile */
  .facultyContent {
    padding: 0 2rem;
  }
  .rightSideContent {
    width: 100%;
    margin: 0;
  }
  .DeptImgSlider {
    width: 100%;
  }
  .imageWithName {
    display: flex;
    align-items: center;
    justify-content: center;
    position: static;
  }
  .imageWithName .centered {
    position: static;
    /* top: 50%;
      left: 47%;
      */
    transform: unset;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--primaryColor);
  }
  .departments {
    padding-left: 2rem;
  }

  .facultySlider {
    width: 100%;
  }
  .DeptSidemenu {
    display: none;
  }
  .DeptRightSide {
    width: 100%;
  }
  .deanMessage {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 0;
  }

  #deanArts .deanMessage img,
  .deanMessage img {
    width: 100%;
    padding-bottom: 1rem;
    margin: 0;
  }

  .deanMessage p {
    width: 100%;
  }
  /* Department News responsive */
  .DeptRightSideNewsAndEvents {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  #FacultyMembers {
    margin: 0;
    padding: 3rem 2rem 0 2rem;
  }
  #DepartmentName {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  #DepartmentName a {
    position: relative;
    width: 95%;
    margin-right: 6rem;
    margin-bottom: 6rem;
    text-align: center;
  }
  #DepartmentName .card .deptContent {
    height: 10vh;
  }
  .officialsContact li {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Faculty Members Responsive*/
  .item.departmentCard {
    flex-direction: column;
  }
  .departmentCard .card {
    width: 100%;
  }

  #FacultyMembers .item .card img {
    /* height: 30rem; */
    width: 100%;
  }
  .FacultyMembersByDepartment {
    grid-template-columns: 1fr 1fr;
  }
  .FacultyMembersByDepartment .card {
    width: 100%;
    height: 100%;
  }

  /* Contact Responsive */
  .locationContainer {
    flex-direction: column;
  }
  .locationText {
    padding: 5rem;
  }
  .iframeContainer {
    padding: 1rem;
  }
  .contactInfo {
    flex-direction: column;
  }
  .contactInfo .info {
    width: 100%;
    order: 2;
  }
  .contactInfo .contactImg {
    width: 100%;
    order: 1;
  }
  .officeImg tr img {
    width: 100%;
    height: 100%;
  }
  /* Footer responsive */
  .footerFlex {
    padding: 3rem 2rem;
    flex-direction: column;
  }
  #footer .footer-into {
    margin-bottom: 2rem;
  }
  #footer .footer-links h6 {
    order: 1;
    margin: 2rem 1rem 0 0;
  }
  .site-footer .container .row #topIcons {
    display: block;
    margin: 0;
  }
}
/* Career Service */
.CSIO {
  display: flex;
  justify-content: space-around;
}
/* .CSIO .Services{
  
  } */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 350px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  /* topnews responsive TAB */
  .rowC {
    display: flex;
    flex-direction: row;
    max-height: 3vh;
  }
  .topNews {
    width: 97vw;
    max-height: 3vh;
  }
  .brandImg {
    width: 100vw !important;
    padding: 3rem !important;
    margin: 0 !important;
  }
  .sub-menu-block {
    display: none;
  }
  .quickLinks,
  #topIcons {
    display: none;
  }
  /* Navigation Responsive Tab*/
  .navbar-brand {
    display: inline-block !important;
  }

  /* Login menu for TAB */
  .mobileViewNavLink {
    display: block !important;
  }
  /* News and Notice Responsive TAB*/
  .NewsAndNoticeContainer {
    flex-direction: column;
    width: 100%;
    padding: 7rem 2rem 0 2rem;
  }
  #news {
    padding-right: 0;
  }
  #news,
  #notice {
    width: 100%;
  }
  #news .newsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Faculty List of Home Page Responsive TAB */
  #faculty {
    height: unset;
    width: 97%;
    padding: 0;
  }
  /* News for department TAB */
  .newsForDepartment .card {
    width: 45%;
  }

  .events-description p {
    font-size: 1.6rem;
  }
  .Upcoming_Events .card .card-body .events-date {
    background-color: var(--primaryColor);
    border-radius: 0.2rem;
    margin-right: 1rem;
    height: 9rem;
    border-bottom: 0.8rem solid var(--secondColor);
    padding-top: 1rem;
    min-width: 9rem;
  }
  /* Faculty Name of Home Page Responsive TAB*/
  #faculty {
    height: unset;
  }

  /* Photo Gallary responsive TAB */
  .photoGallary .card-deck .card {
    flex: 0 1 auto;
  }
  .photoGallary .card {
    width: 28%;
  }

  /* Career TAB */
  .career img {
    margin: 0;
  }

  /* Faculty Members TAB*/
  .FacultyMembersByDepartment {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* About Us TAB */

  /* International Colabrotion TAB */
  .listOfIntnationIns .card {
    width: 16rem;
    padding: 0.3rem;
  }

  .footerFlex {
    padding: 3rem 2rem;
    flex-wrap: wrap;
    text-align: left;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  html {
    font-size: 50%;
  }
  #faculty {
    height: unset;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  #responsive-navbar-nav {
    justify-content: center;
  }
}
/* End  Media Query For Desktop */

/* testing new Navbar */

/* Navigation Styles */

/* Menu Icons for Devices*/

@media only screen and (min-width: 769px) {
  .sub-menu-block {
    padding: 15px;
  }

  /* Sub Menu */
  .nav > a > div.sub-menu-block {
    visibility: hidden;
    background-color: #f2f2f2;
    position: absolute;
    margin-top: 1.2rem;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    z-index: 1025;
    font-size: 16px;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    opacity: 0;

    /*CSS animation applied for sub menu : Slide from Top */
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    -webkit-transform: rotateX(90deg);
    -moz-transform: rotateX(90deg);
    -ms-transform: rotateX(90deg);
    transform: rotateX(90deg);
    -webkit-transform-origin: top center;
    -ms-transform-origin: top center;
    transform-origin: top center;
  }

  .nav > a:hover > div.sub-menu-block {
    background-color: #f2f2f2;
    visibility: visible;
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    -moz-transform: rotateX(0deg);
    -ms-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .nav > a:hover {
    background-color: var(--secondColor);
  }
  .nav > a > div.sub-menu-block > * {
    -webkit-transition-property: opacity;
    -moz-transition-property: opacity;
    -o-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 0.4s;
    -moz-transition-duration: 0.4s;
    -o-transition-duration: 0.4s;
    transition-duration: 0.4s;
    opacity: 0;
  }

  .nav > a:hover > div.sub-menu-block > * {
    opacity: 1;
  }

  /* List Separator: Inner Border */
  .nav > a:after {
    content: "";
    width: 1px;
    height: 62px;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 2;
  }

  /* Drop Down/Up Arrow for Mega Menu */
}

/* Keyframs */
@keyframes hamburger_puls {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.4);
  }
}

/* Quiz  */
/* Created by Sihab */

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}
h2 {
  text-transform: uppercase;
  margin: 0 0 20px;
  font-weight: 800 !important;
  font-size: 36px !important;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600 !important;
  color: #333 !important;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
}
p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}

.section-title {
  margin-bottom: 70px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  font-weight: 800;
  font-size: 36px;
}
.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif !important;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #5ca9fb;
  background-image: linear-gradient(
    to right,
    #5ca9fb 0%,
    #6372ff 100%
  ) !important;
  padding: 14px 34px !important;
  letter-spacing: 1px !important;
  margin: 0 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  border-radius: 25px !important;
  transition: all 0.5s linear !important;
  border: 0 !important;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff !important;
  background-image: none !important;
  background-color: #6372ff !important;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none !important;
  outline-offset: none !important;
}
/* Header Section */
.intro {
  width: 100%;
  padding: 0;
  /* background: url(../img/intro-bg.jpg) center center no-repeat; */
  background-color: #1860aa;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
}
.intro .overlay {
  background: rgba(0, 0, 0, 0.2);
}
.intro h1 {
  font-family: "Raleway", sans-serif;
  color: #fff;
  font-size: 8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 10px;
}
.intro h1 span {
  font-weight: 800;
  color: #5ca9fb;
}
.intro p {
  color: #fff;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}
header .intro-text {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
/* Features Section */
#features {
  position: relative;
  background: #f6f6f6;
  height: 350px;
}
#features i.fa {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
/* About Section */
#QuizDetails {
  padding: 100px 0;
}
#QuizDetails h3 {
  font-size: 24px !important;
  margin: 0 0 20px !important;
  color: #1860aa !important;
  font-weight: normal !important;
}
#QuizDetails p {
  font-size: 18px;
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: darkslategray;
}
/* #about h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0; 
}  */
#QuizDetails .about-text {
  margin-bottom: 6px;
  margin-left: 6px;
  /* list-style: none; */
  padding: 0;
}
/* #about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
} */
/* #about img {
  width: 520px;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
} */
#about p {
  line-height: 24px;
  margin: 30px 0;
}
/* Services Section */
#Schedule {
  padding: 100px 0;
  background-color: #1860aa;
  color: #fff;
}
#Schedule .Schedule-desc {
  margin: 10px 10px 20px;
}
#Schedule h2 {
  color: #fff;
  font-family: sans-serif;
  font-weight: 500 !important;
}
#Schedule .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#Schedule i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#Schedule h3 {
  font-weight: 500 !important;
  padding: 5px 0;
  color: #fff !important;
}
#Schedule p {
  color: #fff !important;
}
#Schedule .service-desc {
  margin-bottom: 40px;
}
/* Portfolio Section */
#portfolio {
  padding: 100px 0;
}
.portfolio-item {
  margin: 1px -15px 0 -14px;
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}
/* Testimonials Section */
#Registration {
  padding: 100px 0;
  background: #f6f6f6;
}
#Registration i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.Registration {
  position: relative;
  padding: 20px;
}
.Registration-image {
  float: left;
  margin-right: 15px;
}
.Registration-image,
.Registration-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.Registration-content {
  position: relative;
  overflow: hidden;
}
.Registration-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
}
.Registration-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #666;
}
/* Team Section */
#Prize {
  padding-top: 100px;
  background-color: rgb(9, 190, 214);
}
#Prize sup {
  padding: 100px 0;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-transform: lowercase;
}
#Prize h3 {
  padding: 100px 0;
  font-size: 30px;
  color: firebrick !important;
  font-family: Georgia, "Times New Roman", Times, serif;
}
#Prize h4 {
  margin: 5px 0;
}
#Prize .team-img {
  width: 240px;
}
#Prize .thumbnail {
  background: transparent;
  border: 0;
}
#Prize p {
  padding: 10px 0 0;
  color: linen;
}
/* Contact Section */
#contact {
  padding: 100px 0 60px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  color: rgba(255, 255, 255, 0.75);
}
#contact .section-title {
  margin-bottom: 40px;
}
#contact .section-title p {
  font-size: 16px;
}
#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  text-align: left;
}
#contact h4 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
#contact h3 {
  color: #fff !important;
  margin-top: 80px;
  margin-bottom: 25px;
  padding-bottom: 20px;
  font-weight: 400 !important;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0 !important;
  background: transparent !important;
  border: 2px solid #fff !important;
}
#contact .btn-custom:hover {
  color: #1f386e !important;
  background: #fff !important;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact .social {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 50px;
  margin-top: 50px;
  text-align: center;
}
#contact .social ul li {
  display: inline-block;
  margin: 0 20px;
}
#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}
#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}
/* Footer Section*/
#footerQuiz {
  background: #f6f6f6;
  padding: 30px 0;
}
#footerQuiz p {
  color: #888;
  font-size: 14px;
}
#footerQuiz a {
  color: #608dfd;
}
#footerQuiz a:hover {
  border-bottom: 2px solid #608dfd;
}

@media (max-width: 768px) {
  #about img {
    margin: 50px 0;
  }
  .intro h1 {
    font-size: 4rem;
    font-weight: 700;
  }
}

.alert {
  padding: 1rem;
  border: 00.1rem solid transparent;
  border: 00.5rem;
}
.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}
.alert-danger {
  color: #a02020;
  background-color: #ffe0e0e0;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 4rem; */
  gap: 1.6rem;

  color: var(--color-medium);
  font-size: 1.4rem;
}

.loader {
  width: 50px;
  height: 24px;
  background: radial-gradient(circle closest-side, currentColor 90%, #0000) 0%
      50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 50% 50%,
    radial-gradient(circle closest-side, currentColor 90%, #0000) 100% 50%;
  background-size: calc(100% / 3) 12px;
  background-repeat: no-repeat;
  animation: loader 1s infinite linear;
}

@keyframes loader {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.officialsContact li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.officialsContact img {
  width: 25rem;
  border: #c2c2c2 5px solid;
}
